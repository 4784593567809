import React, { FC } from 'react'
import dynamic from 'next/dynamic'
import { Container, Box, Stack, SimpleGrid } from '@chakra-ui/react'
import { H3Title } from 'src/uikit'

const Card = dynamic(() => import('src/components/SocialProof/Card'))

interface Props {
  title: string
  items: Array<BoxGlobal.SocialProofBox>
}

const SocialProofList: FC<Props> = ({ title, items }) => {
  return (
    <Box bg="baseWhite" pb="xxl6" pt="xxl6">
      <Container as={Stack} maxW="6xl" textAlign="center">
        <H3Title>{title}</H3Title>
        <SimpleGrid
          columns={{ base: 1, sm: 1, md: 3 }}
          spacing="l"
          textAlign="center"
        >
          {items.map(item => (
            <Card
              key={item.title}
              title={item.title}
              description={item.description}
              icon={item.icon}
            />
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  )
}

export default SocialProofList
